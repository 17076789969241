<template>
  <div class="Development">
    <div class="name">
        发展规划
    </div>
    <div class="collapse">
      <a-row class="collapse_ul" v-for="(item,index) in list" :key="index">
          <a-col class="ul_l" :md="24" :lg="24" :xl="12">
            <img :src="'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Development0'+ (index+1) +'.png'" alt="">
            <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Development04.png" alt="">
          </a-col>
          <a-col class="ul_r" :md="24" :lg="24" :xl="12">
            <h3>{{item.title}}</h3>
            <ul>
              <li v-for="(arry,index) in item.desc" :key="index">{{arry}}</li>
            </ul>
          </a-col>
      </a-row>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'Development',
 components:{},
  data () {
    return {
      list: [
        {
          title: '国家版权局-中国知链',
          desc: ['协助参与制定版权信息接入标准','以公信力节点接入等形式深度参与版权区块链建设','探索运用技术手段固定权属信息','完成版权认证、登记、转让、质押等流程操作，加快溯源取证流程']
        },
        {
          title: '教育部-中国知链',
          desc: ['支持教育用户身份认证、学分银行、数字教育档案存证与追踪','数字教育资源知识产权保护与溯源,隐私保证','招生考试、学历认证、学分互认、求职就业、考核评价等方向的应用']
        },
        {
          title: '武汉大学-中国知链',
          desc: ['开展面向教育领域需求的区块链关键技术应用研究','探索区块链技术在战略型紧缺人才培养教学资源建设和运用']
        },
      ]
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.collapse{
  padding: 20px 0;
  p{
  color: rgba(0, 18, 22, 0.65);
  text-indent: 2em;
  padding: 10px 0;
  line-height:1.769230769230769;
  }
  .collapse_ul{
    padding: 30px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    .ul_l{
      img{
        width: 130px;
        height: 130px;
        margin-right: 30px;
        margin-bottom: 20px;
      }
    }
    .ul_r{
      h3{
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 20px;
        color: #0350A8;
        font-family: PingFangSC-Medium, PingFang SC;
      }
      ul{
        li{
          color: rgba(0, 18, 22, 0.65);
          padding-left: 20px;
          line-height:1.769230769230769;
          position: relative;
        }
        li::before{
          content: '';
          width: 8px;
          height: 8px;
          background: #007DFF;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 9px;
        }
      }
    }
  }
}
.Development {
    padding: 0 32px 0;
}
.name {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,18,22,.85);
    line-height: 36px;
    padding: 32px 0 32px 0;
    border-bottom: 1px solid #ebeef5;
}

</style>
